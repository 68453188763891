import React from "react"
import ChatIcon from "../icon/chatIcon"

import { Col, Container, Row } from "react-bootstrap"

const DirectContact = () => {
  return (
    <div className="direct-contact text-center">
      <Container fluid>
        <Row className="align-items-center">
          <Col md={6} className="px-0">
            <div className="mail-contact">
              <h3>
                Not a fan of filling forms? <br />
                Email us to:
                <span> hello@wowmakers.com</span>
              </h3>
            </div>
          </Col>
          <Col md={6} className="px-0">
            <a href="#CQL" class="chat-contact-link drift-chat-popup">
              <div className="chat-contact">
                <h3>
                  Emails too slow for you? Don't worry! <br />
                  <ChatIcon />
                  <strong>Chat with us now</strong>
                </h3>
              </div>
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default DirectContact
