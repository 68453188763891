import React from "react";

function ChatIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width="30"
      height="22"      
      enableBackground="new 0 0 32 26"
      version="1.1"
      viewBox="0 0 32 26"
      xmlSpace="preserve"
    >
      <path d="M27 22H15.4l-5.8 3.8c-.2.1-.4.2-.6.2-.6 0-1-.5-1-1 0-.3.2-.6.4-.8l6.1-4c.2-.1.4-.2.5-.2h12c1.7 0 3-1.3 3-3V5c0-1.7-1.3-3-3-3H5C3.3 2 2 3.3 2 5v12c0 1.7 1.3 3 3 3h4c.6 0 1 .4 1 1s-.4 1-1 1H5c-2.8 0-5-2.2-5-5V5c0-2.8 2.2-5 5-5h22c2.8 0 5 2.2 5 5v12c0 2.8-2.2 5-5 5z"></path>
      <circle cx="16" cy="11" r="2"></circle>
      <circle cx="8" cy="11" r="2"></circle>
      <circle cx="24" cy="11" r="2"></circle>
    </svg>
  );
}

export default ChatIcon;